<template>
  <div>
    <content-box title="Registration" class="col-md-7 col-xl-5">
      <div class="mb-3">
        <h3 class="mb-4 text-center"></h3>
        <p></p>
      </div>
    </content-box>
  </div>
</template>

<script>
import contentBox from '../../components/contentBox'

export default {
  name: 'registrationClosed',
  components: {
    contentBox,
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style scoped></style>
